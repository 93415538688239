export const articles = [
    {
        id: 1,
        img: '/assets/svg/leader-pana.svg',
        title: 'متلازمة المحتال .. الخوف من الفشل في ظل النجاح',
        brief:'هل شعرت مسبقًا بأنك لا تنتمي لمكان العمل بالرغم من استحقاقك لذلك؟...',
        content: (
            <div>
                <ol className="list-unstyled">
                    <li className="mb-4">
                        <p>
                            هل شعرت مسبقًا بأنك لا تنتمي لمكان العمل بالرغم من استحقاقك لذلك؟ أو أن شخصًا ما سيكتشف احتيالك وإدّعائك بمهاراتك؟ إن مررت بذلك من قبل، فأنت قد تكون ممن قد مرّوا بمتلازمة المحتال.
                        </p>
                    </li>
                    <li className="mb-4">
                        <h5 className="fw-bold">ما هي متلازمة المحتال؟</h5>
                        <p>
                            تُوصف متلازمة المحتال (Imposter syndrome) بأنها ظاهرة نفسية تتمثل بالشك الذاتي للافراد ذوي الإنجازات الكبيرة بإنجازاتهم و قدراتهم و ذكائهم ، حيث لا يستطيع هؤلاء الأفراد استيعاب نجاحهم وبدلاً من تقدير أنفسهم و إنجازاتهم ، يختبرون باستمرار مزيجاً من المشاعر السلبية مثل الشك بأنفسهم والقلق و الاكتئاب و الخوف من أن يتم الكشف عنهم كـ " محتالين " في بيئة عملهم .
                        </p>
                    </li>
                    <li className="mb-4">
                        <p>
                            يمكن أن يعاني العديد من الأشخاص من متلازمة المحتال بغض النظر عن طبيعة أدوارهم أو وظائفهم في المجتمع، كما يمكن أن تؤثر على الأشخاص بمختلف الأعمار سواء في العلاقات الإجتماعية أو علاقات العمل.
                        </p>
                    </li>

                    <li>
                        <h5> يتسع مفهوم متلازمة المحتال ليشمل سمات أساسية التي قد تتواجد (كلَّها أو بعضها) لدى الفرد المصاب بها و هي : </h5>
                        <h6 className="fw-bold">1. إنكار الكفاءة و القدرة:</h6>
                        <p>
                            حيث يميل الأفراد الذين يعانون من متلازمة المحتال الى التقليل من ذكائهم وخبراتهم ومهاراتهم ومواهبهم الطبيعية ، كما يميلون إلى تبني الأفكار السلبية حول أنفسهم  كالفشل وأن ينسبوا نجاحهم بالمؤثرات الخارجية أو الصدفة العشوائية أو الحظ، على الرغم من وجود أدلة على أن إنجازتهم قد تمت من دون تلك العوامل و المؤثرات .
                        </p>
                    </li>

                    <li>
                        <h6 className="fw-bold">2. المثالية:</h6>
                        <p>
                            تُوصف المثالية في الأصل باعتبارها "حاجة الفرد إلى أن يكون الأفضل" ، فإن هذه السمة عبارة عن سلسلة متواصلة من السلوكيات شديدة التنافسية والساعية إلى الكمال والتي تحدث عندما يتم فرض معايير وأهداف غير قابلة للتحقيق عملياً من ِقبل الأفراد الذين يعانون من متلازمة المحتال .
                        </p>
                    </li>
                    <li>
                        <h6 className="fw-bold">3. الخوف من الفشل :</h6>
                        <p>
                            يظهر الخوف من الفشل عند مواجهة المهام المرتبطة بالإنجاز ، حيث يعاني الأفراد الذين يختبرون متلازمة المحتال من القلق والخوف من التعرض للخزي أو الإذلال إذا فشلوا أو قاموا بعمل أسوأ من زملائهم في مهمة معينة؛  وبالتالي، يتم كشفهم على أنهم "محتالون" .
                        </p>
                    </li>
                    <li>
                        <h6 className="fw-bold">4. الخوف من النجاح:</h6>
                        <p>
                            يميل الأفراد الذين يعانون من متلازمة الدخيل إلى أن التبني و الاعتراف بفشلهم أكثر من نجاحاتهم كنوع من ردة الفعل التي قد تدفعهم للقيام بعمل أفضل ، حيث يعتقدون أن النجاح قد يؤدي الى توقعات أعلى لأنفسهم و قد يزيد من أعباء العمل على عاتقهم .
                        </p>
                    </li>
                    <li>
                        <h5 className="fw-bold">أسباب متلازمة المحتال:</h5>
                        <p>
                            على الرغم أن متلازمة المحتال تؤثر سلباً على الصحة النفسية، إلا أنها غير مصنفة ضمن الأمراض النفسية ، ويمكن الإصابة بها نتيجة عدد من الأسباب والعوامل التالية:
                        </p>
                        <h6 className="fw-bold">1. البيئة المحيطة:</h6>
                        <p>
                            تساهم البيئة المحيطة بما فيها الوالدين خصوصاً في تعزيز احتمالية الإصابة بمتلازمة المحتال تبعاً لطريقة تعاملهم مع أولادهم مثل التركيز على تحصيل درجات عالية في الدراسة و الانتقاد الزائد للأخطاء.
                        </p>
                    </li>
                    <li>
                        <h6 className="fw-bold">2. المسؤوليات الجديدة:</h6>
                        <p>
                            مثل الالتحاق بعمل أو مكان دراسة جديد، حيث يعزّز الالتحاق بالعمل أو الدراسة في مكان جديد احتمالية الإصابة بمتلازمة المحتال، وذلك بسبب تركيز الشخص على التميّز و تحقيق الإنجازات .
                        </p>
                    </li>
                    <li>
                        <h6 className="fw-bold">3. الصفات الشخصية:</h6>
                        <p>
                            تعد بعض السمات الشخصية من العوامل التي قد تلعب دورًا في تطوّر متلازمة المحتال، ومن الأمثلة على هذه الصفات التوتر والقلق المفرط انخفاض الثقة بالنفس الشعور بعدم الأمان.
                        </p>
                    </li>
                    <li>
                        <p>
                            كما قد تظهر متلازمة المحتال في بعض المصابين باضطرابات المزاج أو اضطرابات الشخصية.
                        </p>
                    </li>
                    <li>
                        <p>
                            تعد متلازمة المحتال شائعة بين العاملين في المجال الصحي و الأقليات العرقية في بعض البلدان و أصحاب المراكز العالية .
                        </p>
                    </li>
                    <li>
                        <h5 className='fw-bold'>
                            تعد متلازمة المحتال شائعة بين العاملين في المجال الصحي و الأقليات العرقية في بعض البلدان و أصحاب المراكز العالية .
                        </h5>
                    </li>
                    <li>
                        <ul>
                            <li>
                                اليقين بأن الآخرين على علم ومعرفة أكثر منهم .
                            </li>
                            <li>
                                الشعور بأن ذكاء الفرد و قدارته هي أقل بكثير عن ما يعتقده الآخرين  .
                            </li>
                            <li>
                                تدني في احترام الذات .
                            </li>
                            <li>
                                أن ينسب الفرد أيّ نجاح تم تحقيقه إلى الحظ والوقت ، عوضًا عن الاجتهاد والمهارة والذكاء.
                            </li>
                            <li>
                                الشعور بعدم الانتماء أو الاستحقاق للتواجد في مكان ما، وأن الآخرين سوف يكتشفون ذلك في وقت قريب.
                            </li>
                            <li>
                                الشعور الدائم بالتقصير والحاجة للقيام بالعمل بطريقة أفضل في كل مرة .
                            </li>
                            <li>
                                الشعور بالتوتر وعدم الراحة عند ثناء الآخرين عليه أو مدح المهمة التي قام بأدائها.
                            </li>
                            <li>
                                الخوف المستمر من يخيب ظنون الآخرين به.                            </li>
                            <li>
                                ايجاد صعوبة في تخطي ونسيان الأخطاء التي يمرّ بها.
                            </li>
                            <li>
                                الحرص الشديد على الحصول على موافقة الآخرين.
                            </li>
                            <li>
                                الخوف من الفشل، والخوف من الاستبعاد أو تغيّر نظرة الآخرين في حال عدم إنجاز مهمة ما أو عدم انجازها بالشكل الأمثل.
                            </li>
                        </ul>
                    </li>
                    <li>
                        <h5 className='fw-bold'>
                            مضاعفات متلازمة المحتال :
                        </h5>
                        <p>
                            يمكن أن تشمل مضاعفات متلازمة المحتال ، الشعور بالتوتر والقلق باستمرار، مما قد يؤدي إلى زيادة احتمالية الإصابة بالاكتئاب والعزلة الاجتماعية لأن المصابون عادة لا يفضلون التحدث مع الآخرين عن ما يواجهونه من مخاوف.
                        </p>
                    </li>
                    <li>
                        <h5 className='fw-bold'>
                            العلاج :
                        </h5>
                        <p>
                            غالباً ما ينظر الفرد للأفكار التي ترتكز على "جلد الذات" وكأنها جزء من نفسه ولا يمكن التخلص منها ولهذا السبب، يختلف علاج متلازمة المحتال وإدارتها اعتماداً على مستوى الضرر الذي تلحقه بحياة الفرد ويقوم بتحديدها المعالج أو الطبيب المختص .
                        </p>
                        <p>
                            وقد أثبتت عدة أنواع من العلاجات أنها مفيدة كالعلاج السلوكي المعرفي (CBT) وهو نوع من أنواع العلاج يساعد على التعرف على المعتقدات السلبية عندما تظهر و التركيز على استبدالها بأفكار أكثر واقعية برفقة المعالج أو الطبيب المختص .
                        </p>
                    </li>
                    <li>
                        <h5 className='fw-bold'>
                            المصادر:
                        </h5>
                        <a href='https://www.ncbi.nlm.nih.gov/books/NBK585058/#:~:text=Imposter%20syndrome%20is%20highly%20prevalent,the%20female%20students%20experienced%20IS' target="_blank">
                            www.ncbi.nlm.nih.gov
                        </a>
                        <br></br>
                        <a href='https://www.verywellmind.com/imposter-syndrome-and-social-anxiety-disorder-4156469' target="_blank">
                            www.verywellmind.com
                        </a>
                        <br></br>
                        <a href='https://altibbi.com' target="_blank">
                            altibbi.com
                        </a>
                    </li>
                    <br></br>
                    <li>
                        <p><b>كتابة:</b> عبد الكريم الصوص</p>
                    </li>
                </ol>
            </div>
        )
    },
    {
        id: 2,
        img: '/assets/svg/restlessness.svg',
        title: 'الأرق',
        brief:'الأرق اضطراب من اضطرابات النوم الشائعة التي يصعب معه الخلود إلى النوم أو الاستغراق فيه...',
        content: (
            <div>
                <ol className="list-unstyled">
                    <li className="mb-4">
                        <p>
                            الأرق اضطراب من اضطرابات النوم الشائعة التي يصعب معه الخلود إلى النوم أو الاستغراق فيه. ويمكن أيضًا أن يسبب الاستيقاظ قبل الموعد وعدم القدرة على معاودة النوم. وقد تظل تشعر بالإرهاق عند الاستيقاظ. وقد يستنزف الأرق طاقتك ويؤثر في مزاجك. كما يمكن أن يؤثر في صحتك وأدائك في العمل وجودة حياتك
                        </p>
                    </li>
                    <li className="mb-4">
                        <h5 className="fw-bold">قد تشمل أعراض الأرق ما يأتي:</h5>
                        <ul>
                            <li>صعوبة في النوم ليلاً. </li>
                            <li>الاستيقاظ خلال الليل.</li>
                            <li>الاستيقاظ مبكرًا جدًا.</li>
                            <li>الشعور بالتعب أو النعاس أثناء النهار. </li>
                            <li>المزاج السيئ أو الشعور بالاكتئاب أو القلق. </li>
                            <li>صعوبة في الانتباه أو التركيز على المهام أو التذكُّر.</li>
                            <li>ارتكاب أخطاء أكثر أو التعرُّض لمزيد من الحوادث.</li>
                            <li>الشعور بقلق مستمر بشأن النوم.</li>
                        </ul>
                    </li>
                    <li className="mb-4">
                        <p>
                            يختلف مقدار النوم الكافي من شخص إلى آخر. لكن معظم البالغين يحتاجون من 7 إلى 9 ساعات في الليلة.
                        </p>
                    </li>

                    <li>
                        <p>
                            يُصاب كثير من البالغين أحيانًا بالأرق قصير الأمد. وقد يستمر أيامًا أو أسابيع. وعادةً ما يحدث الأرق قصير الأمد بسبب التوتر أو الأحداث المزعجة. وبعض الناس يصابون بالأرق طويل الأمد المعروف أيضًا بالأرق المزمن. وهذا النوع يستمر ثلاثة أشهر أو أكثر. قد يكون الأرق المشكلةَ الأساسية بذاته، أو قد يكون مرتبطًا بحالات طبية أخرى أو أدوية معينة عند تناولها في وقت متأخر بعد الظهر أو في المساء. والنيكوتين الموجود في منتجات التبغ منبه آخر يمكنه أن يسبب اضطراب النوم. يمكن أن يؤثر الكحول على النوم فيجعلك تنام ، ولكنه يمنعك من الوصول إلى مراحل النوم العميق، ويجعلك غالبًا تستيقظ في منتصف الليل.
                        </p>
                    </li>

                    <li>
                        <h5>الأرق والتقدم في العمر</h5>
                        <h6 className="fw-bold">يكثر شيوع الإصابة بالأرق مع تقدم العمر. فكلما تقدمت في العمر، فقد تواجه:
                        </h6>
                        <ol>
                            <li>تغير أنماط النوم. تقل الراحة في النوم غالبًا مع التقدم في العمر، لذلك من المرجح أن توقظك الضوضاء أو أي تغيرات في البيئة المحيطة. وتتقدم ساعة جسمك الداخلية غالبًا مع التقدم في العمر، ومن ثَم تشعر بالتعب في وقت مبكر من المساء وتستيقظ في وقت مبكر من الصباح. لكن عادةً ما يظل كبار السن بحاجة إلى القدر نفسه من النوم الذي يحتاج إليه الشباب.
                            </li>
                            <li>تغير مستوى نشاطك. يمكن أن تقل وتيرة نشاطك البدني أو الاجتماعي. ويمكن أن تؤدي قلة النشاط إلى اضطراب جودة النوم ليلاً. وكلما كنت أقل نشاطًا، زادت احتمالية أخذ قيلولة يومية. ويمكن أن تؤدي القيلولة إلى اضطراب النوم ليلاً.
                            </li>
                            <li>
                                حدوث تغييرات في الصحة. يمكن أن يؤدي الألم المستمر الناتج عن الإصابة بحالات مرضية -مثل التهاب المفاصل أو مشكلات الظهر أو الاكتئاب أو القلق- إلى اضطراب النوم. ويمكن أن تؤدي المشكلات التي تزيد من احتمالية حاجتك إلى التبول ليلاً -مثل مشكلات البروستاتا أو المثانة- إلى حدوث اضطرابات في النوم. ويصبح انقطاع النفس النومي ومتلازمة تململ الساقين أكثر شيوعًا مع التقدم في العمر.
                                • تناوُل قدر أكبر من الأدوية. يستخدم كبار السن عادةً الأدوية التي تُصرف بوصفة طبية بكمية أكبر من الشباب، ويزيد ذلك من احتمالات الإصابة بالأرق المرتبط بالأدوية.
                            </li>
                        </ol>
                    </li>
                    <br></br>
                    <li>
                        <h5 >الأرق لدى الأطفال والمراهقين:</h5>
                        <p>
                            قد تكون مشكلات النوم مصدرًا للقلق بالنسبة إلى الأطفال والمراهقين أيضًا. لكن قد تكون صعوبة النوم أو رفض وقت النوم المعتاد لدى بعض الأطفال والمراهقين ليست إلا بسبب تأخر ساعاتهم البيولوجية. فهم يرغبون في الذهاب إلى الفراش متأخرًا والنوم لوقت متأخر في الصباح.
                            الحالات التي تستلزم زيارة الطبيب
                        </p>
                        <p>
                            إذا كان الأرق يجعل من الصعب عليك ممارسة الأنشطة اليومية، فاستشر الطبيب أو اختصاصي رعاية أولية آخر. سيبحث الطبيب عن سبب مشكلة نومك ويساعد على علاجها. إذا اعُتقد أنك قد تكون مصابًا بأحد اضطرابات النوم، فقد يقترح عليك الطبيب الذهاب إلى مركز لطب النوم لإجراء اختبارات خاصة.
                        </p>
                    </li>
                    <li>
                        <h5>عوامل الخطر:</h5>
                        <h6 className="fw-bold">تقريبًا كل شخص منا تمُر عليه ليلة لا يستطيع النوم فيها. لكن من المرجح أنك مُصاب بالأرق في حال:
                        </h6>
                        <ul>
                            <li>
                                كونك امرأة. قد تؤدي التغيرات في الهرمونات أثناء دورة الحيض وانقطاع الطمث دورًا. فالتعرّق الليلي وهَبَّات الحرارة أثناء انقطاع الطمث غالبًا ما تؤدي إلى اضطراب النوم. كذلك تشيع الإصابة بالأرق أثناء الحمل.
                            </li>
                            <li>
                                كان عمرك أكبر من 60 عامًا. من المرجح أن تُصاب بالأرق مع التقدم في العمر بسبب التغيرات في أنماط النوم والصحة.
                            </li>
                            <li>
                                كنت مصابًا بحالة صحية عقلية أو جسدية. يمكن للعديد من المشكلات التي تؤثر في صحتك العقلية أو الجسدية أن تؤدي إلى اضطراب النوم.
                            </li>
                            <li>
                                كنت تتعرض لتوتر شديد. يمكن أن يسبب التوتر الأرق قصير الأمد. ويمكن أن يؤدي التوتر الشديد أو طويل المدى إلى الأرق طويل الأمد.
                            </li>
                            <li>
                                لم يكن لديك جدول مُنتظم. على سبيل المثال، يمكن أن يؤدي تغيير المناوبات في العمل أو السفر إلى اضطراب في دورة النوم والاستيقاظ.
                            </li>
                        </ul>
                    </li>
                    <br></br>
                    <li>
                        <h5 >المضاعفات:</h5>
                        <h6 className="fw-bold">النوم مهم لصحتك تمامًا كأهمية النظام الغذائي الصحي وممارسة النشاط البدني بانتظام. أيًا كانت الأسباب التي تؤدي إلى منعك من النوم، قد يؤثر الأرق عليك عقليًا وبدنيًا. وقد أفاد الأشخاص المصابون بالأرق بأن جودة حياتهم منخفضة مقارنةً بالأشخاص الذين ينالون قسطًا كافيًا من النوم.
                            قد تشمل مضاعفات الأرق ما يلي:
                        </h6>
                        <ol>
                            <li>
                                أداء ضعيف في العمل أو الدراسة.
                            </li>
                            <li>
                                تباطؤ رد الفعل أثناء القيادة وزيادة خطر التعرض للحوادث.
                            </li>
                            <li>
                                أمراض الصحة العقلية، مثل الاكتئاب أو القلق أو اضطرابات تعاطي المواد المخدرة.
                            </li>
                            <li>
                                زيادة خطر التعرض للإصابة بالأمراض أو الحالات الصحية طويلة المدى، مثل ارتفاع ضغط الدم وأمراض القلب.
                            </li>
                        </ol>
                    </li>
                    <br></br>
                    <li>
                        <h5>الوقاية:</h5>
                        <h6 className="fw-bold">
                            في ما يأتي أمثلة لعادات النوم الجيدة التي يمكن أن تساعد على الوقاية من الأرق:
                        </h6>
                        <ul>
                            <li>
                                التزم بالنوم والاستيقاظ في الوقت ذاته كل يوم، بما في ذلك أيام العطلات الأسبوعية.
                            </li>
                            <li>
                                حافظ على نشاطك ومارس التمارين الرياضية بانتظام . حيث يؤدي النشاط المنتظم والرياضة إلى النوم جيدًا خلال الليل.
                            </li>
                            <li>
                                قلل من أوقات القيلولة أو لا تأخذ قيلولة مطلقًا.
                            </li>
                            <li>
                                قلل من استخدامك الكافيين والكحول والنيكوتين أو لا تستخدم هذه المواد.
                            </li>
                            <li>
                                لا تأكل وجبات كبيرة أو تشرب سوائل كثيرة قبل النوم.
                            </li>
                            <li>
                                اجعل غرفة النوم مريحة للنوم ولا تستخدمها إلا لممارسة الجنس أو النوم.
                            </li>
                            <li>
                                اصنع عادات مريحة قبل النوم، مثل أخذ حمام دافئ أو القراءة أو الاستماع إلى الموسيقى الهادئة.
                            </li>
                        </ul>
                    </li>
                    <br></br>
                    <li>
                        <h5 >
                            المصادر:
                        </h5>
                        <a href='https://www.mayoclinic.org/ar' target="_blank">
                            Mayo clinic
                        </a>
                        <br></br>
                        <a href='https://pubmed.ncbi.nlm.nih.gov/' target="_blank">
                            Pupmed
                        </a>
                    </li>
                    <br></br>
                    <li>
                        <p><b>كتابة: </b> جودي إبراهيم</p>
                    </li>
                </ol>
            </div>
        )
    },
    {
        id: 3,
        img: '/assets/svg/emotional-intelligence.svg',
        title: 'الذّكاء العاطفي Emotional intelligence',
        brief:'ماذا يعني مصطلح الذّكاء العاطفي ، و متى ذُكر لأوّل مرّة ؟...',
        content: (
            <div>
                <ol className="list-unstyled">
                    <li>
                        <h5>
                            ماذا يعني مصطلح الذّكاء العاطفي ، و متى ذُكر لأوّل مرّة ؟
                        </h5>
                        <p>
                            تمّ وصف الذّكاء العاطفي (EI) لأوّل مرّة في عام 1990 من قبل عالميّ النّفس Peter Salovey و
                            John Mayer حيث اقترحا أنّ :
                            <p>
                                الذّكاء العاطفي يمثّل جزءاً مهمّاً من الذّكاء العام للإنسان ، و أنّ الأفراد ذوي المستوى العالي من الذّكاء العاطفي لديهم مهارات معيّنة تتعلّق بتقييم و تنظيم العواطف ، و بالتّالي قدرتهم على تنظيم و إدراك المعلومات العاطفيّة في أنفسهم و في الآخرين أيضاً..
                            </p>
                        </p>
                        <p>
                            و قد عرّف العالمان الذّكاء العاطفي على أنّه :
                            <p>
                                " قدرة الفرد على تنفيذ تفكير دقيق حول العواطف و قدرته على استخدام العواطف و المعرفة العاطفيّة لتوجيهه نحو التْفكير و السّلوك و اتّخاذ القرار ".
                            </p>
                        </p>
                    </li>
                    <li>
                        <h5>
                            نماذج الذّكاء العاطفي :
                        </h5>
                        <h6 className="fw-bold">
                            على مدى العقود الثّلاثة الماضية ظهرت طرق مختلفة لتصوّر الذّكاء العاطفي، والتي تتلّخص بشكل رئيسيّ في ثلاثة نماذج أثّرت في بناء أدوات القياس :
                        </h6>
                        <ol>
                            <li>القدرة</li>
                            <li>السّمات</li>
                            <li>القدرة و السّمات.</li>
                        </ol>
                    </li>
                    <br></br>
                    <li>
                        <h6 className="fw-bold">ماهو " نموذج القدرة " ؟</h6>
                        <p>
                            طوّراه العالمان النّفسيّان Peter Salovey و John Mayer
                            حيث نظرا إلى الذّكاء العاطفي على أنّه شكل من أشكال الذّكاء الفطريّ الّذي يتكوّن من عدّة قدرات و مهارات تؤثّر على كيفيّة فهم الأشخاص و إدارة عواطفهم و مشاعر الآخرين ..
                        </p>
                        <h6 className="fw-bold">
                            ماذا تتضمّن مهارات معالجة العواطف و المشاعر حسب نموذج القدرة ؟
                        </h6>
                        <ol>
                            <li>
                                <b>مهارة إدراك العواطف: </b>
                                من خلال تعرّف الفرد على مشاعره و تقييمها و فهمها و التّعبير عنها و قدرته على التّكيف مع ماهو مهم لتقديم استجابات عاطفيّة أفضل .
                            </li>
                            <li>
                                <b>مهارة التّيسير العاطفي للفكر: </b>
                                يُعرف أيضاً باسم '' الاستيعاب '' و هو يتضمن ربط مشاعر الفرد بأحاسيس أخرى ( مثل الرّؤية و اللّمس.. ) و من ثمّ استخدام هذا التّصور في التّعبير عن مشاعره و صنع قراره .
                            </li>
                            <li>
                                <b>مهارة فهم العواطف وتحليلها: </b>
                                أي بمجرّد أنْ يفهم الفرد ماهية المشاعر و لماذا يشعر بها يمكنه العمل في تقليل السّلبيات من خلال التّفكير في البدائل الإيجابيّة .
                            </li>
                            <li>
                                <b>مهارة التّنظيم الانعكاسيّ للعواطف: </b>
                                عن طريق الاستجابة للموقف بدلاً من الرّد عليه حيث تتضمّن الاستجابة ملاحظة الفرد لما يشعر به و من ثمّ تخصيص الوقت ليقرّر كيفيّة التّصرف بطريقة هادئة و مدروسة .
                            </li>
                        </ol>
                    </li>
                    <br></br>
                    <li>
                        <h6 className="fw-bold">و ماهو " نموذج السّمات " ؟ </h6>
                        <h6>
                            اقترحه الطّبيب النّفسيّ Adrian Furnham حيث عرّف الذّكاء العاطفي على أنّه :
                        </h6>
                        <p>
                            " سمة " كنمط سلوكي مستمرّ مع مرور الوقت ( على عكس المهارة التي تتحسّن مع الوقت والتّدريب ) ، حيث ترتبط هذه السّمة بالميول التّصرفيّة و السّمات الشّخصيّة
                            و هي تتألّف من خمسة عشر بُعداً للشخصيّة ، مجتمعةً تحت أربعة عوامل :
                        </p>
                        <ol>
                            <li>
                                <b>الرّفاهية: </b>
                                لأنّ زيادة الذّكاء العاطفي يسهّل الحصول على المزيد من الحالات العاطفيّة الإيجابيّة وتقليل المزاج السّلبيّ ، و بالتّالي تحقيق شعور أكبر بالرفاهيّة .
                            </li>
                            <li>
                                <b>ضبط النّفس: </b>
                                يلعب الذّكاء العاطفي دوراً مهمّاً في تخفيف التّوتر و التّواصل بصورة فعَّالة و التّعاطف مع الذّات و مع الآخرين ، والتغلُّب على التّحديات والصّراعات في الحياة .
                            </li>
                            <li>
                                <b>العاطفة: </b>
                                إنْ الذكاء العاطفي يسهّل توصيل المشاعر مهما وصلت درجة صعوبتها مثل الاختلاف دون أن تكون غير مقبول و الّتي تحتاج إلى بيئة آمنة و مرحّبة يمكن أن يحدث فيها التّفاعل الإيجابيّ .
                            </li>
                            <li>
                                <b>التّواصل الاجتماعيّ: </b>
                                يشير الذّكاء العاطفي إلى إدراك و فهم عواطف و احتياجات و وجهات نظر الآخرين بدقة مما يؤدي إلى التّفاهم و التّعاون مع الآخرين و تعزيز العلاقات الإيجابيّة معهم .
                            </li>
                        </ol>
                    </li>
                    <br></br>
                    <li>
                        <h6 className="fw-bold">و ماهو " نموذج القدرة و السّمات " ؟</h6>
                        <h6>اقترحه عالم النّفس Daniel Goleman حيث اعتبر أنّ :</h6>
                        <p>
                            الذّكاء العاطفي كنموذج مختلط يشترك في جوانب معيّنة بين القدرة و السّمات ممّا يؤثّر في القدرة على النّجاح في التّعامل مع المتطلّبات والضّغوط البيئيّة ، و يتكوّن من العناصر التّالية :
                        </p>
                        <ul>
                            <li>التّعرف على عواطف الفرد</li>
                            <li>إدارة العواطف</li>
                            <li>التّحفيز الذاتيّ</li>
                            <li>التّعرف على عواطف الآخرين</li>
                            <li>إدارة العلاقات</li>
                        </ul>
                        و الذي من شأن كُلّاً من هذه الكفاءات العاطفيّة و الاجتماعيّة أن تساهم في الأداء الإداريّ والقيادة .
                    </li>
                    <br></br>
                    <li>
                        <h5>أهميّة الذّكاء العاطفي :</h5>
                        <p>
                            يعتبر الذّكاء العاطفي عاملاً أساسيّاً في تحقيق النّجاح على المستوى المهنيّ و الشّخصيّ و الاجتماعيّ ، حيث تمّ الاعتراف بالأهميّة الّتي يجدها الأكاديميّون في دراسة الذّكاء العاطفي في العديد من المجالات .
                        </p>
                        <h6>و سؤالنا الأساسيّ هنا ، كيف يؤثّر الذّكاء العاطفي على مستوياتنا الحياتيّة ؟</h6>
                        <ol>
                            <li>
                                <b>الذّكاء العاطفي على مستوى العمل: </b>
                                هو القدرة على التّعامل بفعاليّة مع المشاعر و العواطف في بيئة العمل تسهم في خلق علاقات إيجابيّة مع زملاء العمل و العملاء..
                                <br></br>
                                على سبيل المثال : " في المهن الّتي تتطلّب العمل مع الأشخاص و العملاء ، تكون متلازمة الإرهاق شائعة "
                                و هي متلازمة نشاهد فيها زيادة الإرهاق العاطفيّ و اللّامبالاة و انخفاض الفعاليّة المهنيّة...
                                <br></br>
                                و هنا يأتي دور الذّكاء العاطفي الّذي يساعد في تغيير مواقف الموظّفين و سلوكيّاتهم في الوظائف الّتي تنطوي على متطلّبات عاطفيّة عن طريق زيادة الرِّضا الوظيفيّ و التّقليل من ضغوطات العمل .
                            </li>
                            <li>
                                <b>الذّكاء العاطفي على مستوى التّعليم: </b>
                                يلعب الذّكاء العاطفي دوراً مهمّاً في تحسين الأداء الأكاديميّ و التّعلم الفعّال لأنّ :
                                <br></br>
                                الطّلاب الّذين يمتلكون مهارات الذّكاء العاطفي يكونون أكثر تفاعلاً و تحفيزاً مع المواد الدّراسيّة و يمتلكون مهارات التّحفيز الذّاتيّ و التّحمل في وجه التّحديات الأكاديميّة .
                                <br></br>
                                بالإضافة إلى المعلّمين و المدرّسين الّذين يمتلكون الذّكاء العاطفي يكونون قادرين على تقديم الدّعم و المساعدة الإضافيّة لطلّابهم عن طريق فهم احتياجاتهم و تطوير مهاراتهم الاجتماعيّة و العاطفيّة .
                            </li>
                            <li>
                                <b>الذّكاء العاطفي على مستوى المتغيّرات النفسيّة: </b>
                                يرتبط الذّكاء العاطفي ارتباطاً مباشراً بالصّحة النّفسيّة ، فهل يتعلق ذلك بقدرة الفرد على إدارة العواطف ؟
                                <br></br>
                                الإجابة : نعم ، إنّ تنظيم الفرد لعواطفه يقلّل من مخاطر الإصابة بالاكتئاب ، والقلق ، والضّغوطات النّفسيّة .
                                <br></br>
                                كما وجد العلماء أنّ الذّكاء العاطفي و الكفاءة الاجتماعيّة الْتي يحقّقها هذا النّوع من الذّكاء ، يرتبطان بانخفاض الضّيق النّفسيّ لدى الإنسان ، و إنّ الذّكاء العاطفي يلعب دوراً حاسماً في بناء علاقات صحيّة و مستدامة بين الناس من خلال التّواصل الفعّال و حلّ النّزاعات و تقديم الدّعم العاطفيّ في العلاقات الشّخصيّة ، و الّذي بدوره يؤثّر تأثيراً إيجابيّاً على الصّحة النّفسيّة .
                                <br></br>
                                بالإضافة إلى إنّ اكتساب المهارات العاطفيّة و الاجتماعيّة الّتي تنشأ عن الذّكاء العاطفي يمكن أن يعمل على تطوير المرونة النّفسيّة ، و هو عامل وقائيّ ضدّ الاضطّرابات النّفسيّة المُحتمل حدوثها عند الفرد .
                            </li>
                        </ol>
                    </li>
                    <br></br>
                    <li>
                        <p><b>كتابة: </b>بتول المحمد</p>
                    </li>
                </ol>
            </div>
        )
    },
    {
        id: 4,
        img: '/assets/img/suicide.png',
        title: 'اليوم العالمي لمحاربة الانتحار – لنتحدث، لنتواصل، لننقذ الأرواح',
        brief:'في 10 سبتمبر من كل عام، يجتمع العالم لإحياء اليوم العالمي لمحاربة الانتحار بهدف...',
        content: (
            <div>
                <ol className="list-unstyled">
                    <li>
                        <p>
                            في <b>10 سبتمبر</b> من كل عام، يجتمع العالم لإحياء <b>اليوم العالمي لمحاربة الانتحار</b> بهدف رفع الوعي حول قضايا الصحة النفسية والوقاية من الانتحار. <b>الانتحار ليس حلاً</b>، بل هو مشكلة معقدة يمكن الوقاية منها عندما نتحدث عنها بصراحة وندعم بعضنا البعض.
                        </p>
                    </li>
                    <li>
                        <h5>حقائق صادمة: </h5>
                        <ul>
                            <li>يُقدَّر أن هناك شخصًا ينتحر كل <b>40 ثانية</b> حول العالم.</li>
                            <li>يؤثر الانتحار على المجتمعات بأسرها، ولا يتوقف الألم عند الضحية، بل يشمل العائلة والأصدقاء  و من يحبنا أيضًا.</li>
                        </ul>
                    </li>
                    <br></br>
                    <li>
                        <h5>لنتحدث عن الصحة النفسية: </h5>
                        <ul>
                            <li>
                                الحديث عن المشاعر السلبية لا يُعتبر ضعفًا، بل هو خطوة شجاعة نحو التعافي.

                            </li>
                            <li>
                                كلما تحدثنا بصدق عن <b>الضغوطات النفسية</b>، كلما كانت فرصنا أكبر في منع وقوع المآسي.
                            </li>
                        </ul>
                    </li>
                    <br></br>
                    <li>
                        <h5>كيف يمكننا المساعدة؟ </h5>
                        <ol>
                            <li>
                                <b>كن مستمعًا جيدًا: </b>
                                اسأل من حولك عن حالتهم النفسية واستمع إليهم بدون إطلاق الأحكام.
                            </li>
                            <li>
                                <b>تعلم علامات الخطر: </b>
                                العزلة المفرطة، الحديث عن الموت، أو تغيير كبير في السلوك يمكن أن تكون إشارات على أن الشخص بحاجة إلى مساعدة.
                            </li>
                            <li>
                                <b>شجّع على طلب المساعدة من المختصين: </b>
                                العلاج النفسي أو الحديث مع مستشار متخصص قد يُحدث فرقًا كبيرًا.
                            </li>
                            <li>
                                <b>تواصل بصدق: </b>
                                قد تكون مجرد كلمة طيبة أو رسالة مشجعة قادرة على إنقاذ حياة.
                            </li>
                            <li>
                                <b>أنت لست وحدك: </b>
                                إذا كنت تشعر بالإرهاق أو الضياع، فتذكر أن هناك دائمًا من يستمع ويهتم لأمرك. لا تتردد في طلب المساعدة.
                            </li>
                        </ol>
                    </li>
                    <br></br>
                    <li>
                        <b>معًا نستطيع أن نحدث فرقًا</b>
                    </li>
                    <br></br>
                    <li>
                        <h5>المصادر :</h5>
                        <a>منظمة الصحة العالمية (WHO).</a>
                        <br></br>
                        <a>الجمعية الدولية لمنع الانتحار (IASP). </a>
                        <br></br>
                        <a>مراكز السيطرة على الأمراض والوقاية منها (CDC)</a>
                    </li>
                    <br></br>
                    <li>
                        <p> <b>كتابة: </b>حمزة البهنسي</p>
                    </li>
                </ol>
            </div>
        )
    },
    {
        id: 5,
        img: '/assets/img/emotional-attachment.jpg',
        title: 'التعلق العاطفي المرضي، كيف نعالج هذا التعلق، وكيف نتعامل مع هذا المرض',
        brief:'سيكولوجية التعلق : ماهو التعلق ؟ وكيف يحصل ؟',
        content: (
            <div>
                <ol className="list-unstyled">
                    <li>
                        <h5>سيكولوجية التعلق :</h5>
                        <h6 className="fw-bold">ماهو التعلق ؟ وكيف يحصل ؟</h6>
                        <p>التعلق هوي أمر بشري فطري موجود في كل إنسان .. </p>
                        <p>
                            الأم تتعلق في ابنها ، الابن يتعلق في والديه أو في المدرسة مع المدرسين أو الأصدقاء .. وبهذا العمر تكون العلاقة بين الآخرين طبيعية .
                            يبدأ الطفل بالنمو شيئاً فشيئاً إلى أن يصبح مراهقاً إلى حدٍ ما ، فيقوم باستدعاء صور خيالية مرسومة من أيام الطفولة .. تجد في طفولتك من كان يعطيك الحلوى ومن كان حنوناً معك ، فتأخذ من هذا وذاك الكثير من الأشياء ، ثم تتشكل في داخلك ( في العقل اللاوعي ) كل هذه الأشياء ، وتظل مكبوتة ومخفية .
                        </p>
                        <p>
                            وحينما تقابل شخصاً ما ، فتجتمع فيه كل تلك الصفات التي مرت في خيالك من الناس الطيبين والمحبوبين لديك ، والذين تركوا أثراً ما في نفسك ومضوا .. لذلك عندما تجد شخصاً تجتمع فيه كل تلك الصفات ، تخرج تلك الصورة التي كانت عالقة في عقلك اللاوعي إلى عقلك الواعي ، فتصبح واقعاً تتعايش معه ، ثم تميل إلى هذا الشخص ، لأن البداية تكون في الميل ، تميل إليه وتشعر أنك تألفه ! وبحسب قدراتك في مهارات التعامل مع الضغوطات ، والقدرة على إدراة الأزمات ، وبحسب قوة الشخصية التي تمتلكها ، تكون سريع التعلق ، أو ربما تأخذ وقتاً طويلاً لتتعلق .. وفي هذه الحالة لا يكون لك هدف أو رؤية أو إدراك لنوعية هذا التعلق ، فقط أنت تريد أن تتعلق ! لذا تجد نفسك قد وقعت في هذا المرض وأنت لا تدري كيف ومتى ولماذا
                        </p>
                    </li>
                    <li>
                        <h5>الأسباب التي توصلنا لهذا المرض :</h5>
                        <ol>
                            <li>الخلافات الأسرية ، والإهانات التي يوجهها الوالدان للأبناء ، تجعلهم لا يشعرون بالحب ، ويلجؤون إلى أول شخص يشعرهم به .</li>
                            <li>عدم تفريغ المشاعر أولاً بأول ، وكبتها وكتمانها وخاصة للوالدين .</li>
                            <li>قد يكون فاقداً للاهتمام خاصة من قِبل الوالدين أو الإخوة أو الأصدقاء .. هذه الأمور تسبب ردات فعل تظهر في الطرف الآخر .</li>
                            <li> أسباب واضطرابات نفسية حصلت معه في الصغر .</li>
                        </ol>
                    </li>
                    <br></br>
                    <li>
                        <h5>الفرق بين الحب والتعلق :</h5>
                        <p>
                            <b>الحب: </b>
                            هو الشيء الذي يشعرك بالسعادة والمتعة ، وبعدم الضغط والقلق والحزن والخوف ، الحب سعادة كبرى ، إحساس جميل ، انفعال إيجابي وليس سلبياً .
                        </p>
                        <p>
                            <b>التعلق: </b>
                            هو الوجه المظلم من الحب ، إذ الكثير من الناس لا تعرف الفرق الحقيقي بينهما ، يظن الشخص أنه وقع في الحب فقط ، وهنا تبدأ الكارثة !
                            التعلق يوصف بكونه علاقة سلبية ، لأنه مبني على خوف وقلق وحزن وضغط نفسي !
                            <br></br>
                            التعلق يعطينا تنافراً بين الشخصين ، لأنه غالباً ما يكون من طرف واحد ، وهذا لأن الطرف الآخر غير مجبوراً .
                            <br></br>
                            (ليس كل تعلق حب ، وليس كل حب تعلق)
                        </p>
                    </li>
                    <li>
                        <h5>كيف يمكننا معرفة هل هذه العلاقة سوية أم مرضية ؟!</h5>
                        <ul>
                            <li>
                                إسأل نفسك : هل هي علاقة متكافئة؟ هل يتمادى هذا الشخص ويوجه شتائم أو إهانات ؟ هل يفكر بك كما تفكر به ؟ هل يبذل قصارى جهده ، أم أنك الوحيد الذي يبذل ويحاول لأجل هذه العلاقة ؟
                            </li>
                            <li>
                                هل أنت عالي التوقعات ؟ إن رفع التوقعات بشكل كبير هو أحد أسباب التعلق المرضي ، فتوقعاتك يجب أن تكون طبيعية ، لأن البشر مهما حاولت أن تكون متشابهة ، فالشخص الآخر لن يراك شبيهاً له ، لذا لا تتوقع الكثير .. وسبب التعلق هو فقدان شيء معين ، لذا عليك أن تسأل نفسك ماذا تحتاج ؟ ماذا ينقصك ؟ لماذا وصلت إلى هذه المرحلة ؟
                            </li>
                        </ul>
                    </li>
                    <br></br>
                    <li>
                        <h5>العلاج: </h5>
                        <ol>
                            <li>
                                إذا كان هناك أخصائي نفسي أو شخص ذو خبرة من الأفضل أن تذهب إليه .
                            </li>
                            <li>
                                يجب أن نتحدث مع أنفسنا عن هذه المشاعر بكل صراحة .
                            </li>
                            <li>
                                تعلم أولاً أن تحب نفسك لكي تتعلم كيف تحب الآخر وتعطيه فرصة كي يحبك بشكل سوي بدون خوف .
                            </li>
                            <li>
                                عليك أن تعي ما يحدث بداخلك ، وتتعامل مع عقلك اللاوعي ، ربما ستجد صعوبة في الفوز بتلك المعركة ، لكنه ليس صعباً عندما تخطو أول خطوة للتعامل مع عقلك اللاوعي .
                            </li>
                            <li>
                                عليك معالجة كل الأشياء التي حدثت في الماضي ولم تعالج ، لكي تستطيع التحرر من القيود والألم .
                            </li>
                            <li>
                                لا تتعلق بكل ما يريده الطفل المجروح الذي بداخلك .
                            </li>
                            <li>
                                تقبل نفسك : من خلال تقبلك لنفسك ستتقبل رفض الآخرين لك ، ومن خلال تصديقك لنفسك أنك تستحق : ستصدق أنك غير مرفوض .
                            </li>
                        </ol>
                    </li>
                    <br></br>
                    <li>
                        <h5>المصادر: </h5>
                        <a>كتاب فخ التعلق  الشيماء عبد العال </a>
                        <br></br>
                        <a>كتاب التعلق العاطفي المرضي سارة كروم </a>
                    </li>
                    <br></br>
                    <li>
                        <p><b>كتابة: </b>بتول حمزة</p>
                    </li>
                </ol>
            </div>
        )
    },
    {
        id: 6,
        img: '/assets/svg/personality-disorder.svg',
        title: 'Borderline personality disorder، ما هو اضطراب الشخصية الحدية؟',
        brief:'اضطراب الشخصية الحدية هو مرض عقلي يؤثر بشدة على قدرة الشخص على إدارة عواطفه...',
        content: (
            <div>
                <ol className="list-unstyled">
                    <li>
                        <p>
                        اضطراب الشخصية الحدية هو مرض عقلي يؤثر بشدة على قدرة الشخص على إدارة عواطفه. يمكن أن يؤدي فقدان السيطرة على العواطف إلى زيادة الاندفاع، والتأثير على شعور الشخص تجاه نفسه، والتأثير سلبًا على علاقاته بالآخرين. تتوفر علاجات فعالة يمكن أن تساعد الأشخاص في إدارة أعراض اضطراب الشخصية الحدية.
                        </p>
                    </li>
                    <li>
                        <h5>ما هي علامات وأعراض اضطراب الشخصية الحدية؟</h5>
                        <p>
                        قد يعاني الأشخاص المصابون باضطراب الشخصية الحدية من تقلبات مزاجية شديدة ويشعرون بعدم اليقين بشأن كيفية رؤيتهم لأنفسهم. يمكن أن تتغير مشاعرهم تجاه الآخرين بسرعة، وتتأرجح من القرب الشديد إلى الكراهية الشديدة. يمكن أن تؤدي هذه المشاعر المتغيرة إلى علاقات غير مستقرة وألم عاطفي.
                        </p>
                        <p>
                        يميل الأشخاص المصابون باضطراب الشخصية الحدية أيضًا إلى رؤية الأشياء بشكل متطرف، مثل أن تكون كلها جيدة أو كلها سيئة. وقد تتغير اهتماماتهم وقيمهم بسرعة، وقد يتصرفون باندفاع أو بتهور.
                        </p>
                        <h6 className="fw-bold">قد تشمل العلامات أو الأعراض الأخرى ما يلي: </h6>
                        <ul>
                            <li>
                            الجهود المبذولة لتجنب الهجران الحقيقي أو المتصور، مثل الانغماس في العلاقات - أو إنهائها بنفس السرعة.
                            </li>
                            <li>
                            نمط من العلاقات المكثفة وغير المستقرة مع العائلة والأصدقاء والأحباء.
                            صورة ذاتية أو شعور مشوه وغير مستقر بالذات.
                            </li>
                            <li>
                            السلوكيات الاندفاعية والخطيرة في كثير من الأحيان، مثل الإسراف في الإنفاق، وممارسة الجنس غير الآمن، وإساءة استخدام المواد، والقيادة المتهورة، والإفراط في تناول الطعام. ومع ذلك، إذا حدثت هذه السلوكيات في الغالب خلال فترات المزاج المرتفع أو الطاقة العالية، فقد تكون أعراضًا لاضطراب المزاج وليس اضطراب الشخصية الحدية.
                            </li>
                            <li>
                            سلوك إيذاء النفس
                            </li>
                            <li>
                            أفكار متكررة حول السلوكيات الانتحارية أو التهديدات.
                            </li>
                            <li>
                            حالات مزاجية شديدة ومتغيرة للغاية، مع نوبات تستمر من بضع ساعات إلى بضعة أيام.
                            </li>
                            <li>
                            الشعور المزمن بالفراغ.
                            </li>
                            <li>
                            الغضب الشديد وغير المناسب أو مشاكل في السيطرة على الغضب.
                            </li>
                            <li>
                            مشاعر الانفصال، مثل الشعور بالانفصال عن الذات، أو مراقبة الذات من خارج الجسم، أو الشعور بعدم الواقعية.
                            </li>
                        </ul>
                        لن يعاني كل من يعاني من اضطراب الشخصية الحدية من كل هذه الأعراض. وتعتمد شدة الأعراض وتكرارها ومدتها على الشخص ومرضه.
                        <br></br>
                        يعاني الأشخاص المصابون باضطراب الشخصية الحدية من معدل أعلى بكثير من إيذاء النفس والسلوك الانتحاري مقارنة بالسكان بشكل عام.
                    </li>
                    <br></br>
                    <li>
                        <h5>ما هي عوامل الخطر لاضطراب الشخصية الحدية؟</h5>
                        <h6 className="fw-bold">
                        تشير الدراسات إلى أن العوامل الوراثية والبيئية والاجتماعية قد تزيد من احتمالية الإصابة باضطراب الشخصية الحدية. وقد تشمل هذه العوامل ما يلي:
                        </h6>
                        <ul>
                            <li>
                                <b>التاريخ العائلي: </b>
                                قد يكون الأشخاص الذين لديهم فرد قريب من العائلة (مثل أحد الوالدين أو الأشقاء) يعاني من المرض أكثر عرضة للإصابة باضطراب الشخصية الحدية بسبب العوامل الوراثية المشتركة.
                            </li>
                            <li>
                                <b>بنية الدماغ ووظيفته: </b>
                                تشير الأبحاث إلى أن الأشخاص المصابين باضطراب الشخصية الحدية قد يعانون من تغيرات بنيوية ووظيفية في الدماغ، وخاصة في المناطق التي تتحكم في الدوافع وتنظيم العواطف. ومع ذلك، ليس من الواضح ما إذا كانت هذه التغيرات أدت إلى الاضطراب أم كانت ناجمة عن الاضطراب.
                            </li>
                            <li>
                                <b>العوامل البيئية والثقافية والاجتماعية: </b>
                                يذكر العديد من الأشخاص المصابين باضطراب الشخصية الحدية أنهم تعرضوا لأحداث مؤلمة في حياتهم، مثل الإساءة أو الهجر أو المشقة، أثناء الطفولة. وقد يكون آخرون قد عانوا من علاقات أو صراعات غير مستقرة أو غير صالحة.
                            </li>
                        </ul>
                    </li>
                    <br></br>
                    <li>
                        <h5>كيف يتم تشخيص اضطراب الشخصية الحدية؟</h5>
                        <p>
                        يمكن لمتخصص الصحة العقلية المرخص له - مثل طبيب نفسي أو طبيب نفساني أو أخصائي اجتماعي سريري - تشخيص اضطراب الشخصية الحدية بناءً على تقييم شامل لأعراض الشخص وتجاربه والتاريخ الطبي العائلي. يمكن أن يساعد الفحص الطبي الدقيق والشامل في استبعاد الأسباب المحتملة الأخرى للأعراض.
                        </p>
                        <p>
                        يتم تشخيص اضطراب الشخصية الحدية عادة في أواخر مرحلة المراهقة أو أوائل مرحلة البلوغ. في بعض الأحيان، قد يتم تشخيص الأشخاص الذين تقل أعمارهم عن 18 عامًا باضطراب الشخصية الحدية إذا كانت أعراضهم كبيرة واستمرت لمدة عام واحد على الأقل.
                        </p>
                    </li>
                    <li>
                        <h5>ما هي الأمراض الأخرى التي يمكن أن تحدث مع اضطراب الشخصية الحدية؟</h5>
                        <p>
                        غالبًا ما يحدث اضطراب الشخصية الحدية مع أمراض عقلية أخرى، مثل اضطراب ما بعد الصدمة (PTSD). يمكن أن تجعل هذه الاضطرابات المتزامنة من الصعب تشخيص اضطراب الشخصية الحدية وعلاجه بشكل صحيح، خاصةً عندما تتداخل أعراض الاضطرابات. على سبيل المثال، قد يكون الشخص المصاب باضطراب الشخصية الحدية أكثر عرضة أيضًا لتجربة أعراض  الاكتئاب الشديد أو اضطراب ما بعد الصدمة أو الاضطراب ثنائي القطب أو اضطرابات القلق أو اضطراب تعاطي المخدرات أو اضطرابات الأكل .
                        </p>
                    </li>
                    <li>
                        <h5>كيف يتم علاج اضطراب الشخصية الحدية؟</h5>
                        <ul>
                            <li>
                            بفضل العلاج القائم على الأدلة، يعاني العديد من الأشخاص المصابين باضطراب الشخصية الحدية من أعراض أقل حدة، وتحسن في الأداء، ونوعية حياة أفضل. من المهم للأشخاص المصابين باضطراب الشخصية الحدية أن يتلقوا العلاج من أخصائي صحة نفسية مرخص.
                            </li>
                            <li>
                            قد يستغرق تحسن الأعراض بعض الوقت بعد بدء العلاج. من المهم للأشخاص المصابين باضطراب الشخصية الحدية وأحبائهم التحلي بالصبر والالتزام بخطة العلاج وطلب الدعم أثناء العلاج.
                            </li>
                            <li>
                            قد يحتاج بعض الأشخاص المصابين باضطراب الشخصية الحدية إلى رعاية مكثفة، وغالبًا ما تكون في المستشفى، لإدارة الأعراض الشديدة، بينما قد يتمكن آخرون من إدارة أعراضهم من خلال الرعاية الخارجية.
                            </li>
                            <li>
                            العلاج النفسي  (يُطلق عليه أحيانًا العلاج بالكلام) هو العلاج الرئيسي للأشخاص المصابين باضطراب الشخصية الحدية. تتم معظم جلسات العلاج النفسي مع أخصائي صحة نفسية مرخص ومدرب في جلسات فردية أو مع أشخاص آخرين في مجموعات. يمكن أن تساعد الجلسات الجماعية الأشخاص المصابين باضطراب الشخصية الحدية على تعلم كيفية التعامل مع الآخرين والتعبير عن أنفسهم بشكل فعال.
                            </li>
                            <li>
                            تم تطوير العلاج السلوكي الجدلي (DBT) خصيصًا للأشخاص الذين يعانون من اضطراب الشخصية الحدية. يستخدم العلاج السلوكي الجدلي مفاهيم اليقظة أو الوعي بالموقف الحالي والحالة العاطفية للشخص. كما يعلم العلاج السلوكي الجدلي المهارات لمساعدة الأشخاص على إدارة المشاعر الشديدة والحد من السلوكيات المدمرة للذات وتحسين العلاقات.
                            </li>
                            <li>
                            يمكن أن يساعد العلاج السلوكي المعرفي الأشخاص المصابين باضطراب الشخصية الحدية على تحديد المعتقدات والسلوكيات الأساسية وتغييرها والتي تأتي من تصورات غير دقيقة ومشاكل في التعامل مع الآخرين. قد يساعد العلاج السلوكي المعرفي الأشخاص على تقليل تقلبات المزاج وأعراض القلق وقد يقلل من عدد السلوكيات المؤذية للذات أو الانتحارية.
                            </li>
                            <li>
                            إن فوائد أدوية الصحة العقلية لعلاج اضطراب الشخصية الحدية غير واضحة، ولا تُستخدم الأدوية عادةً كعلاج رئيسي للمرض. في بعض الحالات، قد يوصي الطبيب النفسي بأدوية لعلاج أعراض معينة أو اضطرابات عقلية مصاحبة مثل تقلبات المزاج أو الاكتئاب. قد يتطلب العلاج بالأدوية رعاية منسقة بين العديد من مقدمي الرعاية الصحية.
                            <br></br>
                            قد تسبب الأدوية أحيانًا آثارًا جانبية لدى بعض الأشخاص. تحدث إلى مقدم الرعاية الصحية الخاص بك حول ما يمكن توقعه من دواء معين. للعثور على أحدث المعلومات حول الأدوية، تحدث إلى مقدم الرعاية الصحية وقم بزيارة موقع إدارة الغذاء والدواء على الويب .
                            </li>
                            <li>
                            العلاج لمقدمي الرعاية وأفراد الأسرة
                            <br></br>
                            هناك حاجة إلى مزيد من البحث لتحديد مدى نجاح العلاج الأسري في علاج اضطراب الشخصية الحدية. وتُظهِر الدراسات التي أجريت على اضطرابات عقلية أخرى أن إشراك أفراد الأسرة يمكن أن يساعد في دعم علاج الشخص. كما يمكن للأسر ومقدمي الرعاية الاستفادة من العلاج.
                            <br></br>
                            يساعد العلاج الأسري من خلال:
                            <ol>
                                <li>
                                السماح للأشخاص بتطوير المهارات اللازمة لفهم ودعم أحد أفراد أسرهم المصاب باضطراب الشخصية الحدية
                                </li>
                                <li>
                                التركيز على احتياجات أفراد الأسرة لمساعدتهم على فهم العقبات والاستراتيجيات لرعاية أحبائهم.
                                </li>
                            </ol>
                            </li>
                        </ul>
                    </li>
                    <br></br>
                    <li>
                        <p><b>كتابة: </b>ماسة حلاوة</p>
                    </li>
                </ol>
            </div>
        )
    }
];
